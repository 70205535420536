import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"

class ErrorAssigningLocker extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.timeout = setTimeout(
      function() {
          this.props.history.push('/' + window.location.search)
      }
      .bind(this),
      3000
    )
  }

  componentWillUnmount(){
    clearTimeout(this.timeout)
  }

  render(){
    return (
      <Row className="full-height align-items-center">
        <Col>
          <Row className="align-items-center justify-content-center">
            <p className="return-locker-text">Ocorreu um erro a libertar o cacifo!</p>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
      location: state.location,
      locker: state.locker
  }
}

export default connect(mapStateToProps)(ErrorAssigningLocker)
