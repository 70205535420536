import axios from 'axios'

const axiosConfig = axios.create({
  baseURL: "https://cuf.lokk.dev/",
  headers: {
    'Content-Type': 'application/json',
  }
})

export default axiosConfig
