import React from "react"
import {Image} from "react-bootstrap"
import "../assets/sass/components/halloween-overlay.scss"

class HalloweenOverlay extends React.Component {
  render() {
    return (
      <div>
        <Image src="images/spider-overlay.gif" className="spider-overlay"/>
        <Image src="images/spiderweb.gif" className="spiderweb-overlay"/>
        <Image src="images/pumpkin.png" className="halloween-pumpkin"/>
        <Image src="images/happy_halloween.png" className="happy-halloween"/>
      </div>
    )
  }
}

export default HalloweenOverlay
