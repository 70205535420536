import React from "react"
import Layout from './layout'
import Router from './router'
import MyWebSocket from './components/MyWebSocket'

export default function App() {
  return (
      <Layout>
        <Router />
        <MyWebSocket />
      </Layout>
  )
}
