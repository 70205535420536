import React from "react"

class CountdownTimer extends React.Component {
  render() {
    return (
      <div id="countdown-timer">
        <svg>
          <circle r="90" cx="100" cy="100"></circle>
        </svg>
      </div>
    )
  }
}

export default CountdownTimer
