import React from "react"
import moment from 'moment'
import {
  Row,
  Col,
  Image
} from "react-bootstrap"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { getPlaceDetails } from '../redux/actions/occupation'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      time: null
    };
  }

  tick() {
    this.setState(state => ({
      date: moment(Date.now()).format('DD-MM-YYYY'),
      time: moment(Date.now()).format('HH:mm:ss')
    }));
  }

  goHome = () => {
    this.props.history.push('/' + window.location.search)
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const locationId = urlParams.get('Location')
    const token = urlParams.get('Token')
    this.props.dispatch(getPlaceDetails(locationId, token))
   this.interval = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    var date = moment(Date.now()).format('DD-MM-YYYY')
    var time = moment(Date.now()).format('HH:mm:ss')
    return (
        <Row className="header-container">
          <Image src="images/header_bar.svg" className="header-bar" />
          <Col>
            <Row className="justify-content-start">
              <Image onClick={this.goHome} src="images/cuf_logo.png" className="header-logo" />
            </Row>
          </Col>
          <Col>
            <Row className="justify-content-center">
              <p className="header-title">Unidade Hospitalar CUF Tejo</p>
              <p className="header-subtitle">{this.props.locationName}</p>
            </Row>
          </Col>
          <Col>
            <Row className="header-datetime justify-content-end">
              <Col lg="3">
                <Row className="justify-content-center">
                  { date }
                </Row>
                <Row className="justify-content-center">
                  { time }
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
      locationName: state.occupation.locationName
  }
}

export default connect(mapStateToProps)(withRouter(Header))
