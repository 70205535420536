import { combineReducers } from "redux"
import occupation from "./occupation/"
import requestLocker from "./requestLocker/"
import mode from "./mode/"

const rootReducer = combineReducers({
  occupation: occupation,
  requestLocker: requestLocker,
  mode: mode
})

export default rootReducer
