import React from "react"
import {
  Row,
  Col,
  Image,
} from "react-bootstrap"
import Spinner from '../../../components/Spinner'

class Processing extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render(){
    return (
      <Row className="align-items-center full-height request-locker-wrapper">
        <Col>
          <Row className="justify-content-center">
            <p className="processing-text">A processar...</p>
          </Row>
        </Col>
      </Row>
    )
  }
}

export default Processing
