import { SET_LOCATION } from '../../actions/requestLocker'
import { SET_LOCKER } from '../../actions/requestLocker'
import { SET_USER } from '../../actions/requestLocker'

const initialState = {
  location: null,
  locker: [],
  user: null
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.locationItem
      };
    case SET_LOCKER:
      return {
        ...state,
        locker: action.locker,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload.user
      };
    default:
      return state;
  }
}
