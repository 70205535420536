import React from "react"
import "../assets/sass/components/spinner.scss"

class Spinner extends React.Component {
  render() {
    return (
      <div>
        <img className="fallback-logo" src="images/cuf_logo.png" alt="logo" />
        <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div>
      </div>
    )
  }
}

export default Spinner
