import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"

class ReturnLockerConfirmation extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.timeout = setTimeout(
      function() {
          this.props.history.push('/' + window.location.search)
      }
      .bind(this),
      5000
    )
  }

  componentWillUnmount(){
    clearTimeout(this.timeout)
  }

  render(){
    return (
      <Row className="full-height align-items-center">
        <Col>
          <Row className="align-items-center justify-content-center">
            <p className="return-locker-text-big">O seu cacifo foi libertado. Por favor feche a porta</p>
          </Row>
          <Row className="align-items-center justify-content-center">
            <p className="return-locker-text-big">Continuação de um bom dia!</p>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
      location: state.location,
      locker: state.locker
  }
}

export default connect(mapStateToProps)(ReturnLockerConfirmation)
