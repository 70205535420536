import React from "react"
import {Image} from "react-bootstrap"
import "../assets/sass/components/snow-overlay.scss"

class XmasOverlay extends React.Component {
  render() {
    return (
      <div>
        <Image src="images/snow-overlay.gif" className="snow-overlay"/>
        <Image src="images/santa.png" className="xmas-santa"/>
        <Image src="images/merry_christmas.png" className="merry-xmas"/>
        <Image src="images/xmas_lights.gif" className="xmas-lights"/>
      </div>
    )
  }
}

export default XmasOverlay
