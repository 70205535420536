import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"

class ErrorWrongLocation extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    this.timeout = setTimeout(
      function() {
          this.props.history.push('/' + window.location.search)
      }
      .bind(this),
      5000
    )
  }

  componentWillUnmount(){
    clearTimeout(this.timeout)
  }

  render(){
    return (
      <Row className="align-items-center full-height wrong-location-wrapper">
        <Col>
          <Row className="align-items-center justify-content-center">
            <p className="wrong-location-text-attention">ATENÇÃO!</p>
          </Row>
          <Row className="align-items-center justify-content-center">
            <p className="request-locker-text">O seu cacifo está em {this.props.location_name}</p>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col className="wrong-location-number-container">
              <Row className="align-items-center justify-content-center">
                {/*<Image src="images/locker_white.svg" className="locker-number-icon"/>*/}
                <p className="wrong-location-locker-number">{this.props.locker}</p>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
      locker: state.requestLocker.locker.locker_number,
      location_name: state.requestLocker.locker.locker_location_name
  }
}

export default connect(mapStateToProps)(ErrorWrongLocation)
