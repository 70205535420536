import React from "react"
import {
  Row,
  Col,
} from "react-bootstrap"
import { connect } from "react-redux"

class ErrorNoIds extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render(){
    return (
      <Row className="full-height align-items-center">
        <Col>
          <Row className="align-items-center justify-content-center">
            <p className="return-locker-text">Terminal não configurado!</p>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps)(ErrorNoIds)
