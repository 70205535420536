export const SET_MODE = 'SET_MODE'

export const setMode = (mode) => (dispatch) =>
new Promise(function(resolve, reject){
  dispatch({
    type: "SET_MODE",
    payload: {
      mode: mode
    }
  })
  resolve()
})
