import { SET_LOCKERS_LIST, SET_LOCATION_NAME, SET_OCCUPATION } from '../../actions/occupation'

const initialState = {
  lockersList: [],
  locationName: null,
  occupation: null
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOCKERS_LIST:
      return {
        ...state,
        lockersList: action.lockersList
      };
    case SET_LOCATION_NAME:
      return {
        ...state,
        locationName: action.locationName
      };
    case SET_OCCUPATION:
      return {
        ...state,
        occupation: action.payload
      };
    default:
      return state;
  }
}
