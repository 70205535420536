import { SET_MODE } from '../../actions/mode'

const initialState = {
  mode: 'requestLocker'
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MODE:
      return {
        ...state,
        mode: action.payload.mode
      };
    default:
      return state;
  }
}
