import React from "react"
import {
  Row,
  Col
} from "react-bootstrap"
import { connect } from "react-redux"
import { getLockers } from '../../../redux/actions/occupation'
import OccupationStats from '../../../components/OccupationStats'
import LockerList from '../../../components/LockerList'

class Occupation extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      request: null,
      hasLoadedData: false,
    }
  }

  componentDidMount(){
    const urlParams = new URLSearchParams(window.location.search)
    const locationId = urlParams.get('Location')
    const token = urlParams.get('Token')
    this.props.dispatch(getLockers(locationId, token)).then(() => {
      this.setState({hasLoadedData: true})
    })

    const request = setInterval(() => {
      this.props.dispatch(getLockers(locationId, token))
    }, 5000)

    this.setState({request: request})
  }

  componentWillUnmount(){
    clearInterval(this.state.request)
  }

  render(){
    const { occupation, lockersList } = this.props
    const { hasLoadedData } = this.state

    return (
      <Row className="align-items-center justify-content-center occupation-wrapper full-height">
        <Col lg="3">
            <OccupationStats hasLoadedData={hasLoadedData} lockersList={lockersList} occupation={occupation}/>
        </Col>
        <Col lg="8">
          <LockerList hasLoadedData={hasLoadedData} lockersList={lockersList}/>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
      occupation: 89,
      lockersList: state.occupation.lockersList
  }
}

export default connect(mapStateToProps)(Occupation)
