import React from "react"

class OccupationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      occupation: 0,
      color: '#40CF9F'
    };
  }

  static getDerivedStateFromProps(nextProps, prevState){
      if(nextProps.occupation != prevState.occupation){
        if(nextProps.occupation > 95){
          return {
            occupation: nextProps.occupation,
            color: '#C7715B'
          }
        }else if(nextProps.occupation > 75){
          return {
            occupation: nextProps.occupation,
            color: '#f7902f'
          }
        }else if(nextProps.occupation <= 75){
          return {
            occupation: nextProps.occupation,
            color: '#40CF9F'
          }
        }
      }
      return null
  }

  render() {
    const { color, occupation } = this.state
    return (
      <div className="occupation-bar-wrapper" onClick={this.props.refreshOccupation}>
        <p className="occupation-bar-text">Ocupação</p>
        <div className="occupation-bar-border" style={{borderColor: color}}>
          <div className="occupation-bar" style={{width: `${occupation}%`, backgroundColor: color}}></div>
        </div>
        <div className="occupation-bar-percentage">
          <p className="occupation-bar-text">{parseInt(occupation)}%</p>
        </div>
      </div>
    )
  }
}

export default OccupationBar
