import React from "react"
import {
  Image
} from "react-bootstrap"
import { withRouter } from 'react-router-dom'

class IconButton extends React.Component {
  render() {
    const {to, icon} = this.props
    return (
      <div className="icon-button-wrapper">
        <Image
          src={icon}
          className="icon-button-icon"
          onClick={() => this.props.history.push(to + window.location.search)}/>
      </div>
    )
  }
}

export default withRouter(IconButton)
