import React from "react"

class MyButton extends React.Component {
  render() {
    const {value, onClick} = this.props
    return (
      <input type="button" value={value} onClick={onClick} className={`my-button ${this.props.color} ${this.props.class} ${this.props.disabled ? "my-button-disabled" : ""}`} disabled={this.props.disabled}/>
    )
  }
}

MyButton.defaultProps = {
  disabled: false
};

export default MyButton
