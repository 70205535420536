import React from "react"
import {
  Col,
  Row,
  Card
} from "react-bootstrap"
import ProgressCircle from './ProgressCircle'
import Spinner from './Spinner'

class OccupationStats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  freeLockers = () => {
    var freeLockers = 0
    this.props.lockersList.forEach((item, i) => {
      if(item.locker_metra_status === 2 && item.status === 0 && item.enabled === 1){
        freeLockers++
      }
    })
    return freeLockers
  }

  occupiedLockers = () => {
    var occupiedLockers = 0
    this.props.lockersList.forEach((item, i) => {
      if(item.status != 0){
        occupiedLockers++
      }
    })
    return occupiedLockers
  }

  disabledLockers = () => {
    var disabledLockers = 0
    this.props.lockersList.forEach((item, i) => {
      if(item.enabled === 0){
        disabledLockers++
      }
    })
    return disabledLockers
  }

  openLockers = () => {
    var openLockers = 0
    this.props.lockersList.forEach((item, i) => {
      if(item.locker_metra_status === 0){
        openLockers++
      }
    })
    return openLockers
  }

  alertLockers = () => {
    var alertLockers = 0
    this.props.lockersList.forEach((item, i) => {
      if(item.locker_metra_status === 1){
        alertLockers++
      }
    })
    return alertLockers
  }

  cleaningLockers = () => {
    var cleaningLockers = 0
    this.props.lockersList.forEach((item, i) => {
      if(item.enabled === 3){
        cleaningLockers++
      }
    })
    return cleaningLockers
  }

  render() {
    const { occupation, lockersList, hasLoadedData } = this.props

    return (
      <Card className="occupation-card">
        <Card.Body>
          <Row className="justify-content-center card-title">
            <p>Informação Geral</p>
          </Row>
          {/*}<Row className="align-items-center justify-content-center">
            <ProgressCircle
              radius="50"
              percentage={occupation}
              height="110"
              width="110"
              strokeWidth="7"
              showText="true"
              textSize="35"/>
          </Row>*/}
          {!hasLoadedData &&
            <Col className="spinner-wrapper">
              <Row className="justify-content-center align-items-center">
                <Spinner />
              </Row>
            </Col>}
          {hasLoadedData &&
          <Row className="justify-content-center stats">
            <div className="occupation-stat available shadow">
              <p className="occupation-stat-text ">Disponíveis: {this.freeLockers()}</p>
            </div>
            <div className="occupation-stat occupied shadow">
              <p className="occupation-stat-text">Ocupados: {this.occupiedLockers()}</p>
            </div>
            <div className="occupation-stat locker-status-0 shadow">
              <p className="occupation-stat-text">Abertos: {this.openLockers()}</p>
            </div>
            <div className="occupation-stat cleaning shadow">
              <p className="occupation-stat-text">Limpeza: {this.cleaningLockers()}</p>
            </div>
            <div className="occupation-stat locker-status-1 shadow">
              <p className="occupation-stat-text">Em alerta: {this.alertLockers()}</p>
            </div>
            <div className="occupation-stat disabled shadow">
              <p className="occupation-stat-text">Fora de serviço: {this.disabledLockers()}</p>
            </div>
          </Row>}
        </Card.Body>
      </Card>
    )
  }
}

export default OccupationStats
