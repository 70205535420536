import React from "react"
import {
  Row,
  Image
} from "react-bootstrap"

class ActionButton extends React.Component {
  render() {
    const {text, handleClick, icon} = this.props
    return (
      <Row className="action-button-wrapper align-items-center">
        <Row className={`justify-content-center action-button align-items-center ${this.props.disabled ? "action-button-disabled" : ""}`} onClick={handleClick}>
          <Image src={icon} className="action-button-icon"/>
          <span className="action-button-text">{ text }</span>
        </Row>
        <Row>

        </Row>
      </Row>
    )
  }
}

ActionButton.defaultProps = {
  disabled: false
};

export default ActionButton
