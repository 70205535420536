import React from "react"
import {
  Row,
  Col,
  Card,
  Image
} from "react-bootstrap"
import Spinner from './Spinner'

class LockerList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const { lockersList, hasLoadedData } = this.props

    const lockerGrid = lockersList.map((d, i) =>
      <div className={`locker`} key={i}>
        <div className="locker-inner">
          <span className="number">{d.locker_metra_id}</span>
          <span className="size">{d.size.toUpperCase()}</span>
          <Row className="locker-logo-wrapper justify-content-center align-items-center">
            <div className={`locker-status-indicator
              ${(d.status === 0) ? "available" : "occupied"}
              locker-status-${d.locker_metra_status}
              ${d.enabled === 0 && "disabled"}
              ${d.enabled === 3 && "cleaning"}`}></div>
            <Image src="/images/cuf_logo.svg" className="locker-logo"/>
          </Row>
        </div>
      </div>);

    return (
      <Card className="locker-list-card">
        <Card.Body>
          <Row className="justify-content-center card-title">
            <p>Informação Individual</p>
          </Row>
          {!hasLoadedData &&
            <Col className="spinner-wrapper">
              <Row className="justify-content-center align-items-center">
                <Spinner />
              </Row>
            </Col>}
          {hasLoadedData &&
            <Col>
              <div className="locker-grid-wrapper">
                { lockerGrid }
            </div>
            </Col>}
        </Card.Body>
      </Card>
    )
  }
}

export default LockerList
