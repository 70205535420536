import React from "react"
import axios from '../../utils/api'
import {
  Row,
  Col,
  Image
} from "react-bootstrap"
import ActionButton from '../../components/ActionButton'
import CountdownTimer from '../../components/CountdownTimer'
import MyButton from '../../components/MyButton'
import { connect } from "react-redux"
import { requestLocker } from '../../redux/actions/requestLocker'
import { setMode } from '../../redux/actions/mode'
import moment from 'moment'

class Home extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isRequesting: false,
    }
  }

  componentDidMount(){
    this.setTimer()
  }

  returnLocker = () => {
    if(this.state.isRequesting === false){
      clearTimeout(this.state.timeout)
      //change redux state to mode: returnLocker then push to return locker location
      this.props.dispatch(setMode('returnLocker')).then(() => {
        this.props.history.push('/return-locker' + window.location.search)
      })
    }else{
      return null
    }
  }

  setTimer = () => {
    this.timeout = setTimeout(
      function() {
          this.props.history.push('/' + window.location.search)
      }
      .bind(this),
      5000
    )

  }

  componentWillUnmount(){
    clearTimeout(this.timeout)
  }

  render(){
    const { isRequesting } = this.state
    const { locker } = this.props

    return (
      <>
      <Row className="align-items-center full-height info-wrapper">
        <CountdownTimer />
        <Col>
          <Row className="align-items-center justify-content-center">
            <p className="request-locker-text">O seu cacifo é o</p>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col className="request-locker-number-container">
              <Row className="align-items-center justify-content-center">
                {/*<Image src="images/locker_white.svg" className="locker-number-icon"/>*/}
                <p className="request-locker-number">{locker}</p>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            { this.props.status_duration > 120 &&
              <MyButton value="Libertar cacifo" color="red" class="release-button" onClick={this.returnLocker}/>
            }
          </Row>
        </Col>
      </Row>
      </>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
      locker: state.requestLocker.locker.locker_number,
      user: state.requestLocker.user,
      status_duration: state.requestLocker.locker.status_duration
  }
}

export default connect(mapStateToProps)(Home)
